import { ButtonAction } from '@/contracts/ButtonHeroAction'

export interface Card {
  title: string
}

export interface CardLinkObj extends Card {
  url: string
}

interface Image {
  url: string
  alt?: string
}

export const HOVER_COLORS = {
  Красный: 'red',
  Серый: 'grey',
}

export interface CardImage extends Card {
  description: string
  url: string
  textLink: string
  image: Image
  colImage?: number
  imageRectangleFirst: Image
  imageRectangleSecond: Image
  col?: number
  md?: number
  mdImage?: number
  sm?: number
  smImage?: number
  hasBorder?: boolean
  hasCoverBg?: boolean
  hover?: 'red' | 'gery' | ''
  actionButtons?: ButtonAction[]
  actionButtonsAlignment?: 'left' | 'center'
}

export interface CardNews extends Card {
  date: string
  url: string
  textLink: string
  delay?: number
}

export interface CardDefault extends Card {
  title: string
  date: string
  url: string
}

export interface CardRating extends Card {
  title: string
  text: string
  description: string
}

export interface CardInsuranceCover extends Card {
  descriptionText: string
  price: string
  value: number | string
  currentValue: number | string
}

export interface CardTitleAndIcon {
  title?: string
  icon?: string
  titleColor?: string
  backgroundColor?: string
  actionButtons?: ButtonAction[]
}

export interface CardTitleDescriptionImage {
  title?: string
  image?: string
  titleColor?: string
  backgroundColor?: string
  description?: string
  descriptionColor?: string
  actionButtons?: ButtonAction[]
  imageHeightMobile?: number
}

export interface CardStep {
  title: string
  titleColor: string
  icon: string
  iconText: string
  backgroundColor: string
  description: string
  descriptionColor: string
  actionButtons: ButtonAction[]
  contentAlignment: 'left' | 'center' | 'right'
}

export interface Chip {
  id: string
  title: string
  bgColor: string
  radius: string
}

export interface CardAlternateSlide {
  title: string
  description: string
  actionButtons: ButtonAction[]
  chips: Chip[]
  mobileTitleAlignment: 'left' | 'center' | 'right' | 'bottom'
  mobileChipsHorizontalAlignment: 'flex-start' | 'center' | 'flex-end'
  mobileDescriptionAlignment: 'left' | 'center' | 'right'
  titleAlignment: 'left' | 'center' | 'right'
  descriptionAlignment: 'left' | 'center' | 'right'
  chipsHorizontalAlignment: 'flex-start' | 'center' | 'flex-end'
  chipsAlignment: 'after-title' | 'before-description' | 'after-description'
  mobileJustifyContent: 'flex-start' | 'space-between' | 'flex-end'
  image: string
  mobileImage: string
}

export type CardService = {
  title?: string
  description?: string | string[]
  radius?: string
  image?: string | string[]
  textPositionVertical?: 'top' | 'bottom'
  textPositionHorizontal?: 'left' | 'right'
  cardType?: 1 | 2 | 4
  link?: string | string[]
  bgColor?: string
  smallCardsTextPosition?: 'left' | 'right'
  isRound?: boolean
}

export type CardServiceList = {
  sizes: number[]
  serviceList: CardService[]
  isSlider: boolean
}

export type TCardAchievement = {
  iconColor: string
  value: string
  subtitle: string
  background: string
  hideIcons: boolean
  sm: number
  col: number
}

export type CardReview = {
  title: string
  description: string
  reviewDate: string
  estimate: string
  countLines: string
  backgroundColor: string
  imageReviewAuthor: string
  nameReviewAuthor: string
  linkReviewAuthor: string
}

export interface ICardSearchResult {
  readonly title: string
  readonly text?: string
  readonly url: string
}
