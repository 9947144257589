












































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { defineComponent, useStore } from '@nuxtjs/composition-api'
import { computed } from '@vue/composition-api'
import { ThemeType } from '../../contracts/Settings'
import Link from '@/components/Link.vue'
import CityFromCookie from '@/components/CityFromCookie.vue'

@Component
class MenuRight extends Vue {
  @Prop() readonly search: Object = {}
  @Prop() readonly offices: Object = {}
  @Prop() readonly support: Object = {}
  @Prop() readonly login: Object = {}
  @Prop() readonly isMobileTablet: Boolean = false
  static options: any
}

export default defineComponent({
  name: 'MenuRight',
  components: { CityFromCookie, Link },
  props: MenuRight.options.props,
  setup(_props, ctx) {
    const { state, commit } = useStore()
    const togglePopup = (): void => ctx.emit('toggle-popup')
    const showCityPopup = () => commit('popupCityList/SET_SHOWN', true)
    return {
      showCityPopup,
      togglePopup,
      isVipTheme: computed(() => state.settings.theme === ThemeType.vip),
    }
  },
})
