// @ts-nocheck
import { listBlockAdapter } from './listBlockAdapter'
import Encryption from '../../../helpers/encryption'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionFormAdapter = (data: any) => {
  const {
    subtitle,
    formType,
    isDarkTheme,
    formTitle,
    contacts: [contacts] = [null],
    successMessage,
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
    eventCategory,
    eventAction,
    'id-recipients': idRecipients,
    'id-regionRecipients': idRegionRecipients,
    agentPhoneNumber,
    subjectEmailText,
    hideDefault,
  } = data

  const encryption = new Encryption()
  const encrypted = encryption.encrypt(
    agentPhoneNumber,
    process.env.FORM_SMS_BY_TEL_SECRET
  )

  return {
    sectionData: sectionDataAdapter(data),
    subjectEmailText,
    idRecipients,
    idRegionRecipients,
    subtitle,
    eventCategory,
    eventAction,
    formType,
    formTitle,
    successMessage,
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
    contacts: contacts && listBlockAdapter(contacts),
    isDarkTheme: isDarkTheme === 'Да',
    hideDefault: hideDefault === 'Да',
    agentPhoneNumber: encrypted,
  }
}
