var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-section',{staticClass:"section-basic",class:{ 'section-cover-eagle': _vm.coverEagle },style:(_vm.styles),attrs:{"id":_vm.sectionData.sectionId || ''}},[_c('f-container',[_c('Loader',{attrs:{"loading":_vm.loading}},[_c('f-row',{staticClass:"section-basic__row"},[_c('f-col',{attrs:{"col":_vm.sectionData && _vm.sectionData.sectionSideTitle ? 9 : 12,"md":8,"sm":2,"order":2,"align-items":_vm.sectionData && !_vm.sectionData.sectionSideTitle ? 'center' : 'start'}},[_vm._t("breadCrumbs"),_vm._v(" "),(_vm.sectionData && _vm.sectionData.sectionMainTitle)?_c(!_vm.$scopedSlots.breadCrumbs ? 'h2' : 'h1',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.getColorTitle(_vm.sectionData.sectionMainTitle)),expression:"getColorTitle(sectionData.sectionMainTitle)"}],tag:"component",staticClass:"section-basic__title title--h2 word-breaking",class:{
              'section-basic__title--no-margin':
                _vm.sectionData.sectionDescription ||
                _vm.sectionData.sectionDate ||
                _vm.titleNoMargin,
              'title--h2': !_vm.$scopedSlots.breadCrumbs,
              'title--h1': _vm.$scopedSlots.breadCrumbs,
              'title-large': _vm.$scopedSlots.breadCrumbs,
            }}):_vm._e(),_vm._v(" "),(_vm.sectionData && _vm.sectionData.sectionDate)?_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.sectionData.sectionDate),expression:"sectionData.sectionDate"}],staticClass:"section-basic__date text--basic word-breaking",class:{
              'no-margin':
                _vm.sectionData &&
                _vm.sectionData.sectionTags &&
                _vm.sectionData.sectionTags.length,
            }}):_vm._e(),_vm._v(" "),(
              _vm.sectionData &&
              _vm.sectionData.sectionTags &&
              _vm.sectionData.sectionTags.length
            )?_c('BlockSectionTags',{attrs:{"list":_vm.sectionData.sectionTags}}):_vm._e(),_vm._v(" "),(_vm.sectionData && _vm.sectionData.sectionDescription)?_c('p',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.sectionData.sectionDescription),expression:"sectionData.sectionDescription"}],staticClass:"section-basic__description word-breaking"}):_vm._e(),_vm._v(" "),_vm._t("sectionContent"),_vm._v(" "),(_vm.sectionData && _vm.sectionData.sectionButtons)?_c('block-buttons',{staticClass:"section-basic__buttons",attrs:{"alignment":_vm.sectionData.sectionButtonsAlignment,"button-actions":_vm.sectionData.sectionButtons || []}}):_vm._e(),_vm._v(" "),(
              _vm.sectionData &&
              _vm.sectionData.sectionLink &&
              _vm.sectionData.sectionLink.link
            )?_c('div',{staticClass:"section-basic__link text--section-link"},[_c('Link',{staticClass:"link--basic link--basic-bold section-link",class:_vm.sectionData.sectionLink.arrowType,attrs:{"url":_vm.sectionData.sectionLink.link}},[_vm._v("\n              "+_vm._s(_vm.sectionData.sectionLink.value)+"\n            ")])],1):_vm._e()],2),_vm._v(" "),(_vm.sectionData && _vm.sectionData.sectionSideTitle)?_c('f-col',{attrs:{"col":3,"md":8,"sm":2,"order":1}},[_c('div',{directives:[{name:"html-safe",rawName:"v-html-safe",value:(_vm.sectionData.sectionSideTitle),expression:"sectionData.sectionSideTitle"}],staticClass:"\n              section-basic__title section-basic__title--side\n              text--subheading\n              word-breaking\n            "})]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }