import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";
import { propertyValuesNormalizer, propertyValuesRelationNormalizer } from "../pageAdapter";

export function sectionPulseFormAdapter(data: any) {
  const { insuranceList = [], description } = data
  return {
    sectionData: sectionDataAdapter(data),
    actionButtons: buttonsAdapter(data.actionButtons),
    description,
    insuranceList: insuranceList.map((insurance) => {
      const { title, description, sum } = propertyValuesNormalizer(insurance.propertyValues)
      const { icon: [{ url } = {}] = [] } = propertyValuesRelationNormalizer(insurance.propertyValuesRelation)
      const { checked } = propertyValuesNormalizer(insurance.propertyValuesEnum)
      return {
        code: insurance.id,
        title,
        description,
        sum,
        icon: url,
        checked: checked === 'Да' || false,
      }
    })
  }
}
