import { render, staticRenderFns } from "./CardImage.vue?vue&type=template&id=1a478cbd&scoped=true&"
import script from "./CardImage.vue?vue&type=script&lang=ts&"
export * from "./CardImage.vue?vue&type=script&lang=ts&"
import style0 from "./CardImage.vue?vue&type=style&index=0&id=1a478cbd&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a478cbd",
  null
  
)

export default component.exports