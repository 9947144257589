












import { defineComponent } from '@nuxtjs/composition-api'
import { computed } from '@vue/composition-api'
import { useNuxtLink } from '@/composition/nuxtLink'

export default defineComponent({
  name: 'Link',
  props: {
    url: {
      type: String,
      default: '',
    },
    textLink: {
      type: String,
      default: '',
    },
    privacyLinkLabel: {
      type: String,
      default: '',
    },
    privacyLinkUrl: {
      type: String,
      default: '',
    },
    append: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '',
    },
    targetA: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isNuxtLink } = useNuxtLink(props.url || '')

    const mappedProps = computed(() =>
      isNuxtLink.value
        ? {
            target: props.target,
            to: props.url || '',
            append: props.append,
            is: 'NLink',
          }
        : {
            target: props.targetA || props.target,
            href: props.url,
            is: 'a',
          }
    )
    return {
      mappedProps,
    }
  },
})
