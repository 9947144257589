






























































































































import { computed, useStore } from '@nuxtjs/composition-api'
import Popup from '@/packages/basis/components/Popup.vue'
import { CardReview as TypeReview } from '@/contracts/Cards'
import Link from '@/components/Link.vue'
import BlockReviewEstimate from '@/components/BlockReviewEstimate.vue'
import { dateAdapter } from '@/apollo/adapters/modules'

export default {
  components: { BlockReviewEstimate, Link, Popup },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    review: {
      type: Object as TypeReview,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const store = useStore()
    const isMobileMq = computed(() => store.getters['mediaQuery/isMobileMq'])
    const alternate = computed(
      () => store.getters['popupReviewDetail/getIsAlternateView']
    )
    const isShown = computed(() => props.open)

    const adaptedDate = () =>
      dateAdapter(props.review.reviewDate, true).replace(' г.', '')

    const maxEstimate = 5
    const emptyEstimate = computed(
      () => maxEstimate - Math.ceil(+props.review.estimate)
    )
    const fullEstimate = computed(() => Math.floor(+props.review.estimate))

    const handleClose = () => {
      emit('close')
    }

    return {
      emptyEstimate,
      fullEstimate,
      alternate,
      isShown,
      adaptedDate,
      isMobileMq,
      handleClose,
    }
  },
}
