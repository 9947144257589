


































import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { HeaderInfo } from '@/contracts/Settings'
import Cookie from '@/components/Cookie'
import SurveyButton from '@/components/SurveyButton.vue'
import ButtonBuyOnline from '@/components/ButtonBuyOnline'
import PopupOldPage from '@/components/PopupOldPage'
import PopupReviewDetail from '@/components/PopupReviewDetail.vue'
import PopupCityList from '@/components/PopupCityList.vue'
import SelectCityForm from '@/components/new-design/SelectCityForm.vue'
import BlockListSeoCities from '@/components/BlockListSeoCities.vue'

export default defineComponent({
  name: 'LayoutBlocks',
  components: {
    BlockListSeoCities,
    SelectCityForm,
    PopupCityList,
    PopupReviewDetail,
    PopupReviewDetail: () => import('@/components/PopupReviewDetail.vue'),
    Cookie,
    ButtonBuyOnline,
    BlockPopupFaq: () => import('@/components/BlockPopupFaq.vue'),
    PopupOldPage,
    SurveyButton,
    SurveyPopup: () => import('@/components/SurveyPopup.vue'),
  },
  setup() {
    const store = useStore()

    const headerInfo = computed(
      () => store.getters['settings/headerInfo'] as HeaderInfo
    )
    const showReviewDetailPopup = computed<boolean>(
      () => store.getters['popupReviewDetail/getShowPopupDetail']
    )
    const openReviewDetailPopup = computed<boolean>(
      () => store.getters['popupReviewDetail/getOpenPopupDetail']
    )
    const reviewDetail = computed(
      () => store.getters['popupReviewDetail/getReview']
    )
    const closePopup = (event: Event) => {
      const { target }: { target: EventTarget | null } = event
      if (target && target.closest('a')) {
        store.commit('popupBuyOnline/TOGGLE_FAQ_POPUP')
      }
    }
    const closePopupReviewDetailHandle = () => {
      store.commit('popupReviewDetail/SET_OPEN_POPUP_DETAIL', false)
    }

    const surveyState = computed(() => store.getters['settings/survey'])
    const hideSurveyBtn = computed(
      () => store.getters['settings/getHideSurveyBtn']
    )

    return {
      headerInfo,
      closePopup,
      closePopupReviewDetailHandle,
      surveyState,
      hideSurveyBtn,
      reviewDetail,
      showReviewDetailPopup,
      openReviewDetailPopup,
    }
  },
})
